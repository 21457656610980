.footer{
    padding: 24px 0 24px 250px;

    [class*="container-"] {
      padding: 0;
    }

    .nav{
        display: inline-block;
        margin-bottom: 0;
        padding-left: 30px;
        list-style: none;
    }

    .nav-item{
      display: inline-block;

      &:first-child a {
        padding-left: 0;
      }
    }

    .nav-link {
      color: $white;
      padding: 0 $padding-base-vertical;
      font-size: $font-size-sm;
      text-transform: uppercase;
      text-decoration: none;

      &:hover{
          text-decoration: none;
      }
    }

    .fuentes {
      margin-top: 30px;
      text-align: center;

      .wordometers-logo, .bolseg-logo, .yanderesa-logo {
        margin: 10px 15px;
        //margin-bottom: 20px;
        
      }
  
      .jhu-logo {
        margin: 10px 15px;
        margin-top: 15px;
      }
    }
    


    .copyright{
        font-size: $font-size-sm;
        line-height: 1.8;
        color: $white;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
        padding: 0 15px;

        .dp-logo {
          max-width: 200px;
          margin-top: 25px;
          margin-bottom: 10px;
        }
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}


@media screen and (max-width: 991px){
  .footer {
    padding-left: 0px;
  }
}

@media screen and (min-width: 992px){

}

@media screen and (max-width: 768px){
  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }
}

@media screen and (max-width: 576px){
  .footer {
    text-align: center;
    .copyright {
      text-align: center;
    }

    .nav{
      float: none;
      padding-left: 0;
    }
  }
}
