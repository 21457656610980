/*.navbar .navbar-brand {
  //padding-top:10px;
}*/

$vaccine-chart-height: 255px;
$vaccine-dev-img: 355px;

.text-tertiary {
  color: $tertiary;
}
.text-purple {
  color: $purple;
}
.alert-primary.strong-color{
  background-color: #2dce89 !important;
} 


.toggle-switch {

  margin-top: 6px;

  span {
    color: #525f7f !important;
  }

  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  label {
    cursor: pointer;
    text-indent: -9999px;
    text-align: left;
    width: 52px;
    height: 26px;
    background: #ccc;
    display: block;
    border-radius: 26px;
    position: relative;
  }
  
  label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 22px;
    transition: 0.3s;
  }
  
  input:checked + label {
    background: #2dce89; // #1d8cf8;
  }
  
  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  label:active:after {
    width: 28px;
  }
}


.hits-counter {
  //padding-top: 8px;
  padding-left: 10px;
  padding-right: 20px;
  .alert {
    margin-top: .6rem;
    padding: 0.5rem 1.25rem;
  }
}


.not_found_img {
  max-width: 80%;
  max-height: 465px;
}

.avatar.flag {
  margin-bottom: 0;
  margin-right: 15px;
}

.quedate_en_casa {
  margin-top: 12px;
  margin-bottom: 12px;
}

.map_loader_container {
  text-align:center;
  padding: 28px 0;
}

.pie_loader_container {
  text-align:center;
  //padding: px 0;
  padding-top: 5px;
  padding-bottom: 20px;
}

.card-chart.lg-chart .chart-area {
  height: 380px;
}
.card-chart.md-chart .chart-area {
  height: 280px;
}

.card-chart.vaccine-chart .chart-area {
  height: $vaccine-chart-height;
}

.card-chart.sm-chart .chart-area {
  height: 220px;
}


.card-chart {
  .btn-group {
    //margin-bottom: 22px;
    margin-bottom: .75rem;
  }
}


.card {
  .card-body {
    h3 {
      span {
        font-size: .5em;
      }
    }
    h4 {
      span.ext {
        font-size: .6em;
      }
    }

  }
}


// VACCINE
.vaccine-dev-img {
  max-width: $vaccine-dev-img;
  width: 85%;
}



// SOCIAL SHARE BUTTONS

.social-share-buttons {
  position: absolute;
  bottom: 35px;
  text-align: center;
  display: block;
  width: 100%;
  button {
    margin: 0 5px;
    outline: none;
    -webkit-transition: ease 300ms all;
    transition: ease 300ms all;
    &:hover {
      -moz-transform: translateY(-4px);
      -webkit-transform: translateY(-4px);
      -o-transform: translateY(-4px);
      -ms-transform: translateY(-4px);
      transform: translateY(-4px);
    }
  }
}


/* New logos Styles */
.qec_logo {
  text-align: center;
  text-transform: none !important;
  margin-bottom: 0;
  font-size: 1.4em;
}
.navbar-brand {
  .logo {
    .logo-img{
      max-width: 55px;
    }
    .logo-text {
      display: inline;
      font-size: 1.2em;
      padding-left: 8px;
    }
  }
}

.navbar.bg-white {
  .logo {
    .logo-text {
      color: $default;
    }
  }
}



.width-auto {
  width: auto !important;
}

// REACT-BOOTSTRAP-TABLE2-PAGINATION
.react-bootstrap-table-pagination { 
  .react-bootstrap-table-pagination-list {
    .react-bootstrap-table-page-btns-ul {
      float: right;
      .page-link {
        padding: 0.22rem 1rem;
        font-size: 0.8rem;
        line-height: 1.5rem;
      }
      .page-item:first-child .page-link {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
      }
      .page-item:last-child .page-link {
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }
    }
  }
  /*.react-bs-table-sizePerPage-dropdown .btn.btn-default {
    background: #2dce89;
    background-image: linear-gradient(to bottom left, #2dce89, #04AC71, #2dce89);
    background-size: 210% 210%;
    background-position: top right;
    background-color: #2dce89;
    transition: all 0.15s ease;
    box-shadow: none;
    color: #ffffff;
  }*/
}

th:focus {
  outline: none;
}



/* Custom Inputs */
// Switch
.custom-switch {
  cursor: pointer;
  .custom-control-label {
    cursor: pointer;
  }
}


@media (max-width: 1300px){
  .vaccine-dev-img {
    max-width: $vaccine-dev-img;
    width: 100%;
  }
}

/* Sidebar retrac */
@media (max-width: 992px){
  .toggle-switch {
    display: block;
    float: right;
  }
  .hits-counter {
    float: right;
    padding: 15px 0;
    .alert {
      display: inline;
    }
  }
  .navbar.bg-white .navbar-brand {
    .quedate_en_casa {
      color: $default;
    }
  }
  .social-share-buttons {
    position: static;
    margin-top: 50px;
  }

  .navbar-brand {
    .logo {
      .logo-img{
        max-width: 40px;
      }
      .logo-text {
        font-size: 1em;
      }
    }
  }

}


@media (max-width: 768px){
  .quedate_en_casa {
    margin-left:.5em;
  }
  .navbar .navbar-brand {
    padding-top: 0px !important;
  }
  .navbar-brand {
    .logo {
      .logo-img{
        max-width: 36px;
      }
      /*.logo-text {
      }*/
    }
  }
  .card-chart.lg-chart .chart-area {
    height: 460px;
  }

  .react-bootstrap-table.table-responsive table {
    table-layout: auto !important;
  }

}

@media (max-width: 480px){
  .card-chart.lg-chart .chart-area {
    height: 500px;
  }
}



